<template>
  <div>
    <div class="problem" v-show="isshow">
      <div class="top">
        <span style="font-size: 18px; font-weight: 400; color: #333">
          问题管理
        </span>
        <el-divider></el-divider>
        <el-button type="primary" @click="addPage()" style="float: left">
          <i class="el-icon-circle-plus-outline" style="margin-right: 5px"></i
          >新增
        </el-button>
        <el-form :inline="true" ref="searchForm" label-width="100px">
          <el-form-item label="问题标题:">
            <el-input
              v-model="searchForm.Keyname"
              placeholder="输入问题标题"
              maxlength="30"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属分类:">
            <!-- <el-select
              v-model="choosetopic"
              style="width: 180px"
              @change="Topicselect"
            >
              <el-option
                v-for="item in Alltopic"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              ></el-option>
            </el-select> -->
            <el-cascader
              v-model="choosetopic"
              :options="options"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>

          <el-form-item label="发布时间:">
            <el-date-picker
              @change="selectTime"
              v-model="releasetime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item>
            <el-button @click="resetForm">重置</el-button>
            <el-button type="primary" @click="search(1)">查询</el-button>
          </el-form-item>
        </el-form>
        <!-- <el-button type="primary" style="margin: 0px 30px 10px" @click="addPage"
          >新增标签</el-button
        > -->
      </div>
      <el-table
        border
        style="width: 100%"
        :data="PageConfigure"
        row-key="index"
        @sort-change="sortChange"
       >
        <el-table-column
          fixed
          label="序号"
          width="80"
          align="center"
          type="index"
          sortable
        >
        </el-table-column>
        <el-table-column label="问题标题" align="center" prop="Title">
        </el-table-column>
        <el-table-column
          label="所属分类"
          align="center"
          prop="HelpCenterCategoryName"
        >
        </el-table-column>
        <el-table-column label="热门开关" width="100" align="center">
          <template slot-scope="scope">
            <el-switch
              @change="hotchange(scope.row)"
              v-model="scope.row.IsHot"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-table-column>
        <!-- <el-table-column
          label="提问用户"
          width="100"
          prop="ArticleNum"
          align="center"
        >
        </el-table-column> -->

        <el-table-column
          label="浏览量"
          width="100"
          prop="ReviewCount"
          align="center"
          sortable="custom"
        >
        </el-table-column>
        <el-table-column
          label="有帮助"
          width="100"
          prop="UsefulCount"
          align="center"
        >
        </el-table-column>
        <el-table-column
          label="没有帮助"
          width="100"
          prop="UnUsefulCount"
          align="center"
        >
        </el-table-column>
         <el-table-column label="新手学堂开关" width="110" align="center">
          <template slot-scope="scope">
            <el-switch
              @change="noviceschoolChange(scope.row)"
              v-model="scope.row.IsShow"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch>
          </template>
        </el-table-column>
        <el-table-column
          label="发布时间"
          align="center"
          width="200"
          prop="CreateTime"
          sortable
        >
        </el-table-column>
        <el-table-column width="300" label="操作" align="center" fixed="right">
          <template slot-scope="scope">
            <span
              class="backSpan IsOverHead"
              @click="detail(scope.row)"
              style="color: #409eff"
            >
              编辑
            </span>
            <span
              class="backSpan"
              @click="aboutpage(scope.row)"
              style="color: #00ae9d"
            >
              配置
            </span>
            <span
              class="backSpan"
              @click="lookfeed(scope.row)"
              style="color: #fcaf17"
            >
              查看反馈
            </span>
            <span
              class="backSpan"
              @click="ProDelet(scope.row)"
              style="color: #cc0000"
            >
              删除
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div class="task-list-page" v-if="PageConfigure !== ''">
        <el-pagination
          @current-change="OnCurrentPageChanged"
          @size-change="handleSizeChange"
          :current-page="Params.PageIndex"
          :page-sizes="[10, 20, 30, 40, 50, 60]"
          layout="prev, pager, next,sizes,jumper,total"
          :page-size="Params.PageSize"
          background
          :total="Params.TotalCount"
        >
        </el-pagination>
      </div>
      <!-- 详情 -->
    </div>
    <div class="formdia" v-show="!isshow">
      <header>
        <el-button
          @click="pageback"
          type="primary"
          size="medium"
          style="margin-left: 10px"
          >返回</el-button
        >
        <el-divider></el-divider>
      </header>
      <main>
        <el-form label-width="100px" class="demo-ruleForm">
          <el-form-item label="问题标题:">
            <el-input
              v-show="edshow"
              v-model="addForm.Title"
              maxlength="30"
              style="width: 70%"
            ></el-input>
            <el-input
              v-show="!edshow"
              v-model="detailForm.Title"
              maxlength="30"
              style="width: 70%"
            ></el-input>
          </el-form-item>
          <el-form-item label="所属分类:">
            <el-cascader
              v-model="addchoosetopic"
              :options="options"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="关键词:">
            <el-input v-show="edshow" v-model="addForm.Keywords" style="width: 70%"></el-input>
            <el-input v-show="!edshow" v-model="detailForm.Keywords" style="width: 70%"></el-input>
          </el-form-item>
          <el-form-item label="描述:">
            <el-input v-show="edshow" v-model="addForm.Description" style="width: 70%"></el-input>
            <el-input v-show="!edshow" v-model="detailForm.Description" style="width: 70%"></el-input>
          </el-form-item>
          <el-form-item label="问题内容:">
            <div v-show="edshow" ref="editor" id="div1">
              <p v-html="edcontent"></p>
            </div>

            <div v-show="!edshow" ref="editor2" id="div2">
              <p v-html="edcontent2"></p>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="submitAdd">提交</el-button>
            <el-button type="info" @click="resetpage">重置</el-button>
          </el-form-item>
        </el-form>
      </main>
    </div>
  </div>
</template>
<script>
import E from "wangeditor";
export default {
  name: "Qadministration",
  data() {
    // var LabelNameRules = (rule, value, callback) => {
    //   if (/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error("名称只允许输入汉字、数字或字母"));
    //   }
    // };
    // var KeyWordRules = (rule, value, callback) => {
    //   if (/^[0-9a-zA-Z]*$/g.test(value)) {
    //     callback();
    //   } else {
    //     callback(new Error("链接关键词只允许输入数字或字母"));
    //   }
    // };
    return {
      // dialogFormVisible: false,
      edshow: true,
      panduan: 0,
      isshow: true,
      formLabelWidth: "100px",
      Rowcontent: {
        TopicArticleId: 0,
        Title: "",
        TopicCategoryId: 0,
        TopicCategoryName: "",
        Img: "",
        Content: "",
        IsShow: true,
        IsHot: true,
        IsShow:true,
        IsDelete: true,
        JinHua: true,
        IsRecommendation: true,
        ReviewCount: 0,
        LikesCount: 0,
        ReplyCount: 0,
        CheckType: 0,
        SortNumber: 0,
        CreateTime: "",
      },
      // rules: {
      //   Keywords: [
      //     { required: true, message: "请输入关键词", trigger: "blur" },
      //   ],
      //   Description: [
      //     { required: true, message: "请输入描述", trigger: "blur" },
      //   ],
      // },
      //列表分页数据
      PageConfigure: [],
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 0,
        TotalPage: 0,
      },
      IsApp: false,
      IsEdit: false,
      pageconfigID: "",
      pageName: "",
      deleteStatus: false,
      choosetopic: [], //分类
      addchoosetopic: [],
      options: [
        {
          value: "-1",
          label: "全部",
        },
      ],
      searchForm: {
        Keyname: "",
        StartTime: "",
        EndTime: "",
        CategoryId: -1,
      },
      addForm: {
        Title: "",
        Content: "",
        Keywords:"",
        Description:"",
        HelpCenterCategoryId: -1,
      },
      detailForm: {
        Title: "",
        Content: "",
        Keywords:"",
        Description:"",
        HelpCenterQuestionId: -1,
      },
      //查询数据
      searchContent: {},
      //线上状态
      Alltopic: [{ value: -1, label: "全部" }],
      Status: "全部",
      // AllStatus: [
      //   { value: "-2", label: "全部" },
      //   { value: "-1", label: "审核不通过" },
      //   { value: "0", label: "审核中" },
      //   { value: "1", label: "审核通过" },
      // ],
      //置顶状态
      //解决重复点击问题
      isDisabled: false,
      releasetime: "", //发布时间
      ruleForm: {
        name: "",
        region: "",
        desc: "",
      },
      //禁止未来时间
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
      ed: "",
      ed2: "",
      edcontent: "",
      edcontent2: "",
      // rules: {
      //   name: [
      //     { required: true, message: "请输入活动名称", trigger: "blur" },
      //     { min: 3, max: 5, message: "长度在 3 到 5 个字符", trigger: "blur" },
      //   ],
      //   region: [
      //     { required: true, message: "请选择活动区域", trigger: "change" },
      //   ],
      //   desc: [{ required: true, message: "请填写活动形式", trigger: "blur" }],
      // },
      proptype:"",//存放column的字符串值
      headers:{},//设置上传请求
    };
  },
  created() {
    this.GetCookie();
    this.searchDate();
    this.GetTopicList();
  },
  mounted() {
    this.init_wangeditor();
  },
  methods: {
    refresh() {
      this.$router.go(0);
    },
    GetCookie(){
      var cookies=document.cookie.split("; ");
      for(var i=0;i<cookies.length;i++){
        var arr=cookies[i].split("=");
          if('tokenAdmin'==arr[0]){
            this.headers={
              'Authorization':'Bearer '+arr[1],
            }
          }
      }
    },
    //富文本
    init_wangeditor() {
      let _this = this;
      const editor = new E("#div1");
      const editor2 = new E("#div2");
      this.ed2 = editor2;
      // this.editor = new E(this.$refs.editor);
      editor2.config.zIndex = 1;
      editor2.config.height = 400;
      editor2.config.placeholder = "输入正文";
      editor2.config.showLinkImg = false;
      editor2.config.uploadImgServer = _this.$api.UploadFileV2;
      editor2.config.uploadImgParams = {
        type: "image",
      }
      editor2.config.customUploadImg = function(resultFiles,insertImgFn){
        let file= resultFiles[0];
        let size = file.size;
        if(size>2048000)return _this.$message.warning('添加失败，请上传小于2M的图片');
        let imgType = ['jpg','png','jpeg'];
        let type=file.name.substring(file.name.lastIndexOf('.')+1,file.name.length);
        let boolean = imgType.some(function (item, index) {return item.toLowerCase() == type.toLowerCase();})
        if (!boolean) return _this.$message.warning('请上传图片格式为jpg/jpeg/png');
        // console.log(file)
        let formData = new FormData();
        formData.append("file",file);
         _this.$post(_this.$api.UploadFileV2+'?module=adsense&fileExpand=.'+type,formData).then(res=>{
          // console.log(res)
          if(res.code==200) {
            insertImgFn(res.data);
            _this.uploadimg = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
      }

      //插入视频
      editor2.config.uploadVideoAccept = ["mp4"];
      editor2.config.uploadVideoServer = 
      "https://api.ers-business.test-01.54test.cn/Common/UploadFile/V2?module=email&fileExpand=.mp4";
      editor2.config.uploadVideoHeaders= _this.headers;
      editor2.config.showLinkVideo = false;
      editor2.config.uploadVideoName ="file";
      editor2.config.uploadVideoParams = {
        type: "mp4",
      };
      editor2.config.uploadVideoHooks = {
        customInsert:function(insertVideo,result){
          if (result.isSuccess) {
            insertVideo(result.data);
          }
        }
      };

      editor2.config.onchange = (html) => {
        this.detailForm.Content = html;
      };
      editor2.config.menus = [
        "head", // 标题
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "justify", // 对齐方式
        "splitLine", //分割线
        "image", // 插入图片
        "link", // 插入链接
        "video",//上传视频
        "undo", // 撤销
      ];
      editor2.create();

      this.ed = editor;
      // this.editor = new E(this.$refs.editor);
      editor.config.zIndex = 1;
      editor.config.height = 400;
      editor.config.placeholder = "输入正文";
      editor.config.showLinkImg = false;
      editor.config.uploadImgServer = _this.$api.UploadFileV2;
      editor.config.uploadImgParams = {
        type: "image",
      }
      editor.config.customUploadImg = function(resultFiles,insertImgFn){
        let file= resultFiles[0];
        let size = file.size;
        if(size>2048000)return _this.$message.warning('添加失败，请上传小于2M的图片');
        let imgType = ['jpg','png','jpeg'];
        let type=file.name.substring(file.name.lastIndexOf('.')+1,file.name.length);
        let boolean = imgType.some(function (item, index) {return item.toLowerCase() == type.toLowerCase();})
        if (!boolean) return _this.$message.warning('请上传图片格式为jpg/jpeg/png');
        // console.log(file)
        let formData = new FormData();
        formData.append("file",file);
         _this.$post(_this.$api.UploadFileV2+'?module=adsense&fileExpand=.'+type,formData).then(res=>{
          // console.log(res)
          if(res.code==200) {
            insertImgFn(res.data);
            _this.uploadimg = res.data;
          } else {
            _this.$message.error(res.msg);
          }
        })
      }
      //插入视频
      editor.config.uploadVideoAccept = ["mp4"];
      editor.config.uploadVideoServer = 
      "https://busapi.1renshi.com/Common/UploadFile/V2?module=email&fileExpand=.mp4";
      editor.config.uploadVideoHeaders =_this.headers;
      editor.config.showLinkVideo = false;
      editor.config.uploadVideoName ="file";
      editor.config.uploadVideoParams = {
        type: "mp4",
      };
      editor.config.uploadVideoHooks = {
        customInsert:function(insertVideo,result){
          if (result.isSuccess) {
            insertVideo(result.data);
          }
        }
      };

      editor.config.onchange = (html) => {
        this.addForm.Content = html;
      };
      editor.config.menus = [
        "head", // 标题
        "fontSize", // 字号
        "italic", // 斜体
        "underline", // 下划线
        "strikeThrough", // 删除线
        "justify", // 对齐方式
        "splitLine", //分割线
        "image", // 插入图片
        "link", // 插入链接
        "video",//上传视频
        "undo", // 撤销
      ];
      editor.create();
    },
    //获取数据
    searchDate() {
      this.$get(this.$api.GetAdminQuestionList, {
        ...this.Params,
      }).then((res) => {
        this.PageConfigure = res.Data;
        console.log(this.PageConfigure);
        this.Params.TotalCount = res.TotalNumber;
        this.Params.TotalPage = res.TotalPage;
      });
    },
    //排序
    sortChange(column){
      console.log("排序",column.prop,column.order)
      if(column.prop =='ReviewCount'){
        if(column.order == 'ascending'){
          this.Params.SortPageviews = 2
        }else if(column.order == 'descending'){
          this.Params.SortPageviews = 1
        }else{
          this.Params.SortPageviews = 0
        }
        this.searchDate()
      }
    },
    //获取话题
    GetTopicList() {
      let _this = this;
      this.$get(this.$api.GetAllTopHelpCenterCategor).then((res) => {
        console.log(res.Data);
        if (res.Code == 200) {
          res.Data.forEach((item, index) => {
            _this
              .$get(
                _this.$api.GetNextHelpCategorsByParentId +
                  "?ParentCategoryId=" +
                  item.HelpCenterCategoryId
              )
              .then((val) => {
                let a = [];
                val.Data.forEach((name) => {
                  a.push({
                    value: name.HelpCenterCategoryId,
                    label: name.HelpCenterCategoryName,
                  });
                });
                _this.options.push({
                  value: item.HelpCenterCategoryId,
                  label: item.HelpCenterCategoryName,
                  children: a,
                });
              });
          });
        }
      });
    },

    //热门开关
    hotchange(val) {
      console.log(val);
      this.$post(
        this.$api.HelpCenterOperate + `?questionId=${val.HelpCenterQuestionId}&operationType=3`
      ).then((res) => {
        if (res.IsSuccess) {
          this.$message({
            message: "更改成功",
            type: "success",
            duration: 1000,
          });

          this.searchDate();
        } else {
          val.IsHot = false;
          //console.log(res.Message)
          this.$message({
            message: res.Message,
            type: "warning",
            duration: 1000,
          });
          this.searchDate();
        }
      });
    },
    // 新手学堂开关
    noviceschoolChange(val) {
      console.log(val);
      this.$post(
        this.$api.HelpCenterOperate + `?questionId=${val.HelpCenterQuestionId}&operationType=2`
      ).then((res) => {
        if (res.IsSuccess) {
          this.$message({
            message: "更改成功",
            type: "success",
            duration: 1000,
          });

          this.searchDate();
        } else {
          val.IsShow = false;
          this.$message({
            message: "新手学堂显示已经超过9个，请取消一些",
            type: "warning",
            duration: 1000,
          });
          this.searchDate();
        }
      });
    },
    //跳转到反馈页
    lookfeed(val) {
      this.$router.push({
        path: "/feedbackpage",
        query: { id: val.HelpCenterQuestionId },
      });
    },

    //跳转配置页
    aboutpage(val) {
      let arr = val.relationQuestionIds;
      console.log(arr)
      this.$router.push({
        path: "/aboutquestion",
        query: { id: val.HelpCenterQuestionId, son: arr},
      });
    },
    //选择时间
    selectTime() {
      console.log(this.releasetime);
      this.searchForm.createTime = this.releasetime;
    },
    //选择状态
    selectStatus() {
      console.log(this.Status);
      this.searchForm.Status = this.Status;
    },
    //查询
    search(num) {
      let _this = this;
      if (this.searchForm.Keyname.trim()) {
        this.searchContent.KeyWords = this.searchForm.Keyname.trim();
      }
      if (this.choosetopic[0] !== -1) {
        this.searchContent.CategoryId = this.choosetopic[1];
      } else {
        this.searchContent.CategoryId = -1;
      }
      if (this.searchForm.createTime) {
        this.searchContent.StartTime = this.searchForm.createTime[0];
        this.searchContent.EndTime = this.searchForm.createTime[1];
      }
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      console.log(this.searchContent);
      this.$get(this.$api.GetAdminQuestionList, {
        ...this.searchContent,
        ...this.Params,
      }).then((res) => {
        console.log(res);
        _this.PageConfigure = res.Data;
        _this.searchContent = {};
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },

    //重置查询
    resetForm() {
      this.searchForm.Keyname = "";
      this.searchForm.createTime = "";
      this.choosetopic = [];
      this.releasetime = "";

      this.searchDate();
    },

    //话题选取
    handleChange(value) {
      console.log(value);
      // console.log(this.choosetopic);
    },

    //防抖
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    //新增
    addPage() {
      this.edshow = true;
      this.isshow = false;
      this.panduan = 1;
      this.choosetopic = [];
      this.addForm.Title = "";
      this.addForm.Content = "";
      this.addForm.Keywords = "";
      this.addForm.Description="";
      this.addForm.HelpCenterCategoryId = -1;
      this.addchoosetopic = [];
      this.edcontent = "";
    },
    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.search();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.search();
    },
    //打开详情
    detail(val) {
      this.edshow = false;
      this.isshow = false;
      this.panduan = 2;
      this.detailForm.Title = val.Title;
      this.detailForm.HelpCenterQuestionId = val.HelpCenterQuestionId;      
      this.detailForm.Keywords = val.Keywords;
      this.detailForm.Description = val.Description;
      let arr = val.RelationId.split("_");
      console.log(val)
      this.addchoosetopic = arr.map(Number);
      console.log(this.addchoosetopic);
      this.ed2.txt.html(val.Content);
      this.edcontent2 = val.Content;
    },

    //新增问题提交
    submitAdd() {
      if (this.addchoosetopic[0] == -1 || this.addchoosetopic.length == 0) {
        this.$message({
          message: "请选择分类",
          type: "warning",
          duration: 1000,
        });
        return;
      } else {
        if (this.panduan == 1) {
          this.addForm.HelpCenterCategoryId = this.addchoosetopic[1];
        } else {
          this.detailForm.HelpCenterCategoryId = this.addchoosetopic[1];
        }
      }
      if (this.panduan == 1) {
        if (this.addForm.Title.trim() == "") {
          this.$message({
            message: "请填写问题标题",
            type: "warning",
            duration: 1000,
          });
          return;
        }
        if (this.addForm.Keywords.trim() == "") {
          this.$message({
            message: "请填写关键词",
            type: "warning",
            duration: 1000,
          });
          return;
        }
         if (this.addForm.Description.trim() == "") {
          this.$message({
            message: "请填写描述",
            type: "warning",
            duration: 1000,
          });
          return;
        }
        
        let rule = this.addForm.Content.replace(/<[^<>]+>/g, "").replace(/&nbsp;/gi,"");
        console.log(rule);
        let a = false;
        if (rule == "") {
          a = true;
        }
        let regu = "^[ ]+$";
        let re = new RegExp(regu);
        if (re.test(rule)) {
          a = true;
        }
        if (this.addForm.Content == "" || a) {
          this.iscontent = true;
          this.$message({
            message: "请填写问题内容",
            type: "warning",
            duration: 1000,
          });
          return;
        }
      } else {
        if (this.detailForm.Title.trim() == "") {
          this.$message({
            message: "请填写问题标题",
            type: "warning",
            duration: 1000,
          });
          return;
        }
        let rule2 = this.detailForm.Content.replace(/<[^<>]+>/g, "").replace(
          /&nbsp;/gi,
          ""
        );
        console.log(rule2);
        let a = false;
        if (rule2 == "") {
          a = true;
        }
        let regu = "^[ ]+$";
        let re = new RegExp(regu);
        if (re.test(rule2)) {
          a = true;
        }
        console.log(this.detailForm.Content,a)
        if (this.detailForm.Content == "" || a) {
          this.iscontent = true;
          this.$message({
            message: "请填写问题内容",
            type: "warning",
            duration: 1000,
          });
          return;
        }
        if (this.detailForm.Keywords.trim() == "") {
          this.$message({
            message: "请填写关键词",
            type: "warning",
            duration: 1000,
          });
          return;
        }
         if (this.detailForm.Description.trim() == "") {
          this.$message({
            message: "请填写描述",
            type: "warning",
            duration: 1000,
          });
          return;
        }
      }
      if (this.panduan == 1) {
        this.$post(this.$api.AddQuestion,{ ...this.addForm }, null, ).then(
          (res) => {
            if (res.Code == 200) {
              this.$message({
                message: "问题新增成功",
                type: "success",
                duration: 1000,
              });
              this.pageback();
            }
          }
        );
      } else if (this.panduan == 2) {
        this.$post(this.$api.EditQuestion,{ ...this.detailForm },null, ).then(
          (res) => {
            if (res.Code == 200) {
              this.$message({
                message: "问题编辑成功",
                type: "success",
                duration: 1000,
              });
              this.resetForm();
              this.pageback();
            }
          }
        );
      }
    },
    //重置
    resetpage() {      
      this.addForm.Title = "";
      this.addForm.Keywords = "";
      this.addForm.Description="";
      this.addForm.Content = "";
      this.addchoosetopic = [];
      this.detailForm.Title = "";
      this.detailForm.Content = "";
      this.detailForm.Keywords = "";
      this.detailForm.Description="";      
      this.ed.txt.html("");
      this.ed2.txt.html("");
    },
    //删除
    ProDelet(row) {
      this.$confirm("确认删除，删除后数据不可恢复", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      }).then(() => {
        this.$post(
          this.$api.HelpCenterOperate + `?questionId=${row.HelpCenterQuestionId}&operationType=1`
        )
          .then((res) => {
            if (res.Code == 200) {
              this.$message({
                message: "删除成功",
                type: "success",
                duration: 1000,
              });
              this.searchDate();
            }
          })
          .catch((err) => {
            console.log(err);
          });
      });
    },
    //返回
    pageback() {
      this.isshow = true;
      this.searchDate();
      this.edcontent = "";
      this.ed.txt.html("");
      this.ed2.txt.html("");
      this.edcontent2 = "";
      this.addchoosetopic = [];
    },
  },
};
</script>
<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.problem {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  span.img {
    display: inline-block;
    width: 50px;
    height: 50px;
    background: red;
  }

  .top {
    padding-left: 10px;
  }

  .backSpan {
    padding: 0px 10px;
    cursor: pointer;
  }

  .task-list-page {
    margin-top: 10px;
  }

  /deep/.SwitchStatus {
    .el-switch__label {
      position: absolute;
      display: none;
    }

    .el-switch__core:after {
      left: 4px;
    }

    /* 打开时文字位置设置 */
    .el-switch__label--right {
      z-index: 1;
      right: 25px;
      color: #fff;
    }

    /* 关闭时文字位置设置 */
    .el-switch__label--left {
      z-index: 1;
      left: 25px;
      color: #606266;
    }

    /* 显示文字 */
    .el-switch__label.is-active {
      display: block;
    }
  }

  // 打开时按钮位置
  /deep/.SwitchStatus.el-switch.is-checked .el-switch__core::after {
    left: 100%;
    margin-left: -20px;
  }

  /deep/.SwitchStatus.el-switch .el-switch__core, .el-switch .el-switch__label {
    width: 70px !important; /* 开关按钮的宽度大小 */
  }
}

// 详情样式
.formdia {
  background: #fff;
  min-height: calc(100vh - 100px);
  padding: 10px 10px;
  border-radius: 10px;

  .content {
    width: 90%;
    min-height: 500px;
    word-wrap: break-word;
  }
}

/deep/.el-date-editor .el-range-separator {
  width: 6%;
}

/deep/.procontent {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-height: 28px;

  img {
    display: none;
  }
}
</style>